import React from "react";
import { graphql } from "gatsby";
import { MainLayout } from "../layouts";
import { DirectoryListItems } from "../components";
import { Helmet } from "react-helmet";
import ogImg from "../images/metadata.png";

const DirectoryPage = ({ data }) => {
  // content from site metadata
  const { siteUrl } = data.site.siteMetadata;

  return (
    <MainLayout>
      <Helmet>
        <title> The Yes-code Directory | yescode.org</title>
        <meta
          name="description"
          content="Tools that make building software feel like magic."
        />
        <meta name="keywords" content="code, developers, manifesto" />
        <meta property="og:url" content="https://www.yescode.org/directory" />
        <meta property="og:title" content="Yes-code" />
        <meta
          property="og:description"
          content="Tools that make building software feel like magic."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`${siteUrl}${ogImg}`} />
        <meta property="og:image:width" content="1080" />
        <meta property="og:image:height" content="608" />
        <meta property="og:image:alt" content="Yes-code " />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="The Yes-code Directory | yescode.org"
        />
        <meta
          name="twitter:description"
          content="Tools that make building software feel like magic."
        />
        <meta name="twitter:image" content={`${siteUrl}${ogImg}`} />
        <link rel="icon" href="/" />
        <html lang="en" />
      </Helmet>
      <DirectoryListItems />
      <div>
        <p>
          If you want to be included email{" "}
          <a href="mailto:hello@yescode.org">hello@yescode.org</a> 💌
        </p>
      </div>
    </MainLayout>
  );
};

export default DirectoryPage;

export const query = graphql`
  query Directory {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
